/* eslint-disable react/prop-types */
import * as React from 'react';
import { Link, graphql } from 'gatsby';
import { FaPlus } from 'react-icons/fa';
import Layout from '../Components/Layout/Layout';
import Testimonial from '../Components/Testimonial/Testimonial';
import '../Components/Testimonial/testimonials.css';
import SEO from '../Components/seo';

// markup
const TestimonialsPage = ({ data }) => (
  <>
    <SEO title="Testimonials" />
    <Layout page="Testimonials">
      <main id="main">
        <section id="testimonials" className="testimonials">
          <div className="container">
            <div className="row">
              {data.testimonials.people
                .map(({ frontmatter, rawMarkdownBody }) => (
                  <Testimonial
                    name={frontmatter.name}
                    image={frontmatter.image}
                    content={rawMarkdownBody}
                    key={frontmatter.image}
                  />
                ))}

              <div className="col-lg-6 mt-4">
                <div className="testimonial-item">
                  <center>
                    <Link to="/contact" className="btn">
                      <FaPlus />
                      {' '}
                      Share my testimonial
                    </Link>
                  </center>

                </div>
              </div>
            </div>

          </div>
        </section>
      </main>
    </Layout>
  </>

);

export const pageQuery = graphql` query TestimonialsQuery {
  testimonials: allMarkdownRemark (filter: {fileAbsolutePath: {regex: "/(testimonials)/.*\\.md$/"}}) {
    people: nodes {
      frontmatter {
        name
        image
      }
      rawMarkdownBody
    }
  }
}
`;
export default TestimonialsPage;
